import React, { useState, useEffect, useRef } from "react";
// import { useParams, Link } from "react-router-dom";
// import { Accordion } from 'react-bootstrap';

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import Header from "../../../layout/header";
import Footer from "../../../layout/footer";

// import banner from "../../../assets/img/banner-wasteHero.png";
import img001 from "../../../assets/img/Main Web Banner_2024 Waste Hero School Competition1.jpg";
// import img002 from "../../../assets/img/hero_02.jpg";
import sorn from "../../../assets/img/icon/ss.png";
import img01 from "../../../assets/img/20-01-2568/1_0.jpg";
import img02 from "../../../assets/img/20-01-2568/2_0.jpg";
import img03 from "../../../assets/img/20-01-2568/3_0.jpg";
import img04 from "../../../assets/img/20-01-2568/4_0.jpg";
import img05 from "../../../assets/img/20-01-2568/5_0.jpg";
import img06 from "../../../assets/img/20-01-2568/6_0.jpg";
import img07 from "../../../assets/img/20-01-2568/7_0.jpg";
import img08 from "../../../assets/img/20-01-2568/8_0.jpg";
import img09 from "../../../assets/img/20-01-2568/9_0.jpg";
import img10 from "../../../assets/img/20-01-2568/10_0.jpg";
import img11 from "../../../assets/img/20-01-2568/11_0.jpg";
import img12 from "../../../assets/img/20-01-2568/12_0.jpg";
import img13 from "../../../assets/img/20-01-2568/13_0.jpg";
import img14 from "../../../assets/img/20-01-2568/14_0.jpg";
import img15 from "../../../assets/img/20-01-2568/15_0.jpg";
// import img02 from "../../../assets/img/hero_2.jpg";
// import img03 from "../../../assets/img/hero_3.jpg";
// import vector1 from "../../../assets/img/icon/vector_1.svg";
// import vector2 from "../../../assets/img/icon/vector_2.svg";
// import vector3 from "../../../assets/img/icon/vector_3.svg";
// import vector4 from "../../../assets/img/icon/vector_4.svg";
// import vector5 from "../../../assets/img/icon/vector_5.svg";
// import vector6 from "../../../assets/img/icon/Malaysia.svg";

import Loading from "../../../components/loading";
import { getData } from "../../../services/getService";

const Main = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [dataOne, setDataOne] = useState({});

    const called = useRef(true);

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchDataOne()]).then(
                (res) => {
                    setIsLoading(false);
                }
            );
        }
        return () => {
            called.current = false;
        };
    }, []);

    const fetchDataOne = async () => {
        getData("pageawards", "promotion")
            .then((response) => {
                if (response.status === "success") {
                    setDataOne(response.data)
                }
            })
            .catch((e) => {
                console.log(e.response.data.error);
            });
    };

    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3 },
    };

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="activities" sub="partnership" />
                <div className="wrapper bg-white box-index">
                    {isLoading ? <Loading /> : null}
                    <section className="pb-md-5 bg-top">
                        <div className="container-fluid">
                            <div className="row justify-content-center pb-md-5 text-start">
                                <div className="col-12 col-md-12 col-lg-12 p-0">
                                    <div className="">
                                        <img src={process.env.REACT_APP_IMG_LINK + "/uploads/awards/" + dataOne.id + '/' + dataOne.img} className="w-100 rounded" alt="" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-9 mt-3 mt-md-4">
                                    <div className="font-50 weight-700">{dataOne.topic}</div>
                                    <div className="mb-5 pt-3">
                                        <div className="font-18 text-justify content-custom"
                                            dangerouslySetInnerHTML={{
                                                __html: dataOne.detail,
                                            }}
                                        />
                                        <div>
                                            <div className="banner-custom-b">
                                                <AliceCarousel mouseTracking
                                                    autoPlay
                                                    autoPlayInterval={4000}
                                                    animationDuration={2000}
                                                    animationType="slide"
                                                    touchTracking={false}
                                                    infinite
                                                    handleDragStart
                                                    controlsStrategy="alternate"
                                                    disableDotsControls
                                                    responsive={responsive}
                                                    renderPrevButton={() => {
                                                        return <p className="position-absolute">
                                                            <img src={sorn} className="sorn-left" style={{ height: '40px' }} />
                                                        </p>
                                                    }}
                                                    renderNextButton={() => {
                                                        return <p className="position-absolute end-0">
                                                            <img src={sorn} className="sorn-right" style={{ height: '40px' }} />
                                                        </p>
                                                    }} >

                                                    <img src={img01} alt="" className="w-100 p-2"/>
                                                    <img src={img02} alt="" className="w-100 p-2"/>
                                                    <img src={img03} alt="" className="w-100 p-2"/>
                                                    <img src={img04} alt="" className="w-100 p-2"/>
                                                    <img src={img05} alt="" className="w-100 p-2"/>
                                                    <img src={img06} alt="" className="w-100 p-2"/>
                                                    <img src={img07} alt="" className="w-100 p-2"/>
                                                    <img src={img08} alt="" className="w-100 p-2"/>
                                                    <img src={img09} alt="" className="w-100 p-2"/>
                                                    <img src={img10} alt="" className="w-100 p-2"/>
                                                    <img src={img11} alt="" className="w-100 p-2"/>
                                                    <img src={img12} alt="" className="w-100 p-2"/>
                                                    <img src={img13} alt="" className="w-100 p-2"/>
                                                    <img src={img14} alt="" className="w-100 p-2"/>
                                                    <img src={img15} alt="" className="w-100 p-2"/>
                                                </AliceCarousel>
                                            </div>
                                        </div>
                                        <div >
                                        <div class="sub-topic weight-700 mt-3">About the Organisers</div>
<p style={{fontWeight: "bold"}}><b>SEAMEO SEPS</b></p>
<p style={{textAlign: "justify"}}>SEAMEO Regional Centre for Sufficiency Economy Philosophy for Sustainability (SEAMEO SEPS) was established as one of the 26 SEAMEO Regional Centres under the Southeast Asian Ministers of Education Organization (SEAMEO). SEAMEO SEPS was established in 2019 and hosted by the Ministry of Education of Thailand, with its aims to promote the philosophy, principles and values of the Sufficiency Economy as an instrument for achieving Sustainable Development Goals (SDGs) among 11 Southeast Asian Countries, namely, Brunei Darussalam, Cambodia, Indonesia, Lao PDR, Malaysia, Myanmar, Philippines, Singapore, Thailand, Timor-Leste, and Vietnam. </p>
<p style={{fontWeight: "bold"}}><br/></p>
<p style={{fontWeight: "bold"}}><b><a href="https://www.seaohun.org" target="_blank">SEAOHUN</a></b></p>
<p style={{textAlign: "justify"}}>The Southeast Asia One Health University Network (SEAOHUN), established in 2011, is an academic and research network promoting the One Health concept. Comprising over 100 educational institutions from eight ASEAN countries, SEAOHUN builds professional capacity to address complex One Health issues, conducts research for evidence-based decision-making, and assists governments with public health surge capacity. The SEAOHUN Secretariat is located in Mueang district, Chiang Mai, Thailand.</p>
<p style={{fontWeight: "bold"}}><br/></p>
<p style={{fontWeight: "bold"}}><b><a href="https://www.chevron.com" target="_blank">CHEVRON</a></b></p>
<p style={{textAlign: "justify"}}>Chevron is one of the world’s leading integrated energy companies. As a company of problem solvers, we look to the future of energy with optimism. We provide affordable, reliable, and ever-cleaner energy to people in the Eurasia Pacific region that is essential to achieving a more prosperous and sustainable world. We invest in community programmes that help advance the UN’s sustainable development goals and strive to empower people to meet their full potential. </p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </section>

                    <Footer page="activities" sub="partnership" />
                </div>
            </div>
        </>
    );
};

export default Main;
