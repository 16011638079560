import React, { useState, useEffect, useRef } from "react";

import Header from "../../layout/header";
import Footer from "../../layout/footer";
import footer from "../../assets/img/banner-whatwedo.png";

import { getData } from "../../services/getService";
import Loading from "../../components/loading";

const CentresOverview = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [dataOne, setDataOne] = useState({});
    const [dataTwo, setDataTwo] = useState([]);

    const called = useRef(true);

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchDataOne(), fetchDataTwo()]).then(
                (res) => {
                    setIsLoading(false);
                }
            );
        }
        return () => {
            called.current = false;
        };
    }, []);

    const fetchDataOne = async () => {
        getData("pageawards", "wedo")
            .then((response) => {
                if (response.status === "success") {
                    setDataOne(response.data)
                }
            })
            .catch((e) => {
                console.log(e.response.data.error);
            });
    };
    const fetchDataTwo = async () => {
        getData("whatwedo", "wedo")
            .then((response) => {
                if (response.status === "success") {
                    setDataTwo(response.data)
                }
            })
            .catch((e) => {
                console.log(e.response.data.error);
            });
    };





    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="about" sub="whatwedo" />
                <div className="wrapper bg-white box-index">
                    {isLoading ? <Loading /> : null}
                    <div className="position-relative d-none d-md-block bg-top"  style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_LINK +
                                "/uploads/awards/" +
                                dataOne.id +
                                "/" +
                                dataOne.img})` }}>
                        {/* <img
                            src={
                                process.env.REACT_APP_IMG_LINK +
                                "/uploads/awards/" +
                                dataOne.id +
                                "/" +
                                dataOne.img
                            }
                            className="w-100"
                            alt=""
                        /> */}
                        <div className="w-100 pt-5 pb-5">
                            <div className="container mb-5 mt-lg-5 pt-md-5 pt-lg-5 pb-5">
                                <div className="row text-start justify-content-center" >
                                    <div className="col-12 col-md-12 col-lg-10">
                                        <div className="row text-start" >
                                            <div className="col-12 col-md-12 col-lg-12">
                                            <div className="font-60 weight-700 yellow-black">
                                                    {dataOne.topic}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-7">
                                                
                                                <div className="content-custom pe-5">
                                                    <div className="yellow-black"
                                                        dangerouslySetInnerHTML={{
                                                            __html: dataOne.detail,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="position-relative pt-5 d-block d-md-none bg-top"
                        style={{ backgroundImage: `url(${footer})` }}
                    >

                        <div className="w-100 pb-5 box-res-white">
                            <div className="container">
                                <div className="row text-start">
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="font-60 weight-700">
                                            {dataOne.topic}
                                        </div>
                                        <div className="text-justify content-custom">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: dataOne.detail,
                                                }}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <section className="position-relative box-margin-top-lop">
                        <div className="container">
                            <div className="row text-start justify-content-center">
                                <div className="col-12 col-md-12 col-lg-10">
                                    <div className="sub-topic weight-700 mb-3">
                                        Capacity Building
                                    </div>
                                    <div className="font-18 text-justify content-custom">
                                        The Centre’s capacity building activities target students, teachers, managers, administrators and education officials from relevant government agencies and departments from the region. Using a combination of face-to-face and online delivery mechanisms, these activities aim to enhance decision-making capabilities through the integration of a SEP mindset across all aspects of school life. The Centre works with teachers to drive sustainability curricula, content, classroom and extra curricula activities that reflect the particular contexts and circumstances of individual schools. It also provides training for school administrators and managers so they can apply a SEP Mindset to support planning and financial management, and leverage all available resources through family and community involvement.
                                    </div>
                                    <div className="mt-5 mb-3 mb-md-5 pb-4">
                                        <a href="#" className="btn btn-wedo yellow-passtel position-relative ">SEP4School</a>
                                        <a href="#" className="btn btn-wedo peat-passtel ms-1 ms-md-3 position-relative ">SEP-ED</a>
                                        <a href="#" className="btn btn-wedo sky-passtel ms-1 ms-md-3 position-relative ">SEP Youth Camp</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
                    <div className="position-relative box-margin-top-lop-b">
                        {dataTwo.length ? dataTwo.map((val, index) => (

                            <section className={index === 0 ? "position-relative section-striped bg-unset" : "position-relative section-striped"} key={index}>
                                <div className={index === 0 ? "container pb-3" : "container py-3"}>
                                    <div className="row text-start justify-content-center">
                                        <div className="col-12 col-md-12 col-lg-10">
                                            <div className="sub-topic weight-700 mb-3 pt-3">
                                                {val.topic}
                                            </div>
                                            <div className="text-justify content-custom">
                                                {val.detail}
                                            </div>
                                            <div className="mt-5 mb-3 mb-md-3">
                                                {val.button.filter(employee => employee.name !== '').map((vall, indexx) => (
                                                    <a href={vall.link} key={indexx} className={"btn btn-wedo position-relative me-2 mb-4 " + vall.color}>
                                                        {vall.name}
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        )) : null}

                    </div>
                    <Footer page="about" sub="whatwedo" />
                </div>
            </div>
        </>
    );
};

export default CentresOverview;
