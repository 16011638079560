const menuData = [{
    id: 1,
    name: 'Home',
    active: 'home',
    link: '/',
    target: '',
    status: true,
    submenu: []
  }, {
    id: 2,
    name: 'About Us',
    active: 'about',
    link: false,
    target: '',
    status: true,
    submenu: [{
      id: 21,
      name: 'Who We Are',
      active: 'whoweare',
      link: '/about/whoweare',
      target: '',
      status: true,
      supersubmenu: []
    }, {
      id: 22,
      name: 'SEP Concept',
      active: 'sepconcept',
      link: '/about/sepconcept',
      target: '',
      status: true,
      supersubmenu: []
    }, {
      id: 23,
      name: 'What We Do',
      active: 'whatwedo',
      link: '/about/whatwedo',
      target: '',
      status: true,
      supersubmenu: []
    }, {
      id: 24,
      name: 'Organisational Structure',
      active: 'organisationalStructure',
      link: '/about/organisationalStructure',
      target: '',
      status: true,
      supersubmenu: []
    }]
  }, {
    id: 3,
    name: 'Thematic Areas',
    active: 'thematicAreas',
    link: false,
    target: '',
    status: true,
    submenu: [{
      id: 31,
      name: 'SEP & Culture',
      active: 'sepCulture',
      // link: '/thematicAreas/sepCulture',
      link: '#',
      target: '',
      status: true,
      supersubmenu: []
    }, {
      id: 32,
      name: 'SEP & Environment',
      active: 'sepEnvironment',
      // link: '/thematicAreas/sepEnvironment',
      link: '#',
      target: '',
      status: true,
      supersubmenu: []
    }, {
      id: 33,
      name: 'SEP & Technology',
      active: 'sepTechnology',
      // link: '/thematicAreas/sepTechnology',
      link: '#',
      target: '',
      status: true,
      supersubmenu: []
    }, {
      id: 34,
      name: 'SEP & Life Skills',
      active: 'sepLifeSkills',
      // link: '/thematicAreas/sepLifeSkills',
      link: '#',
      target: '',
      status: true,
      supersubmenu: []
    }]
  }, {
    id: 4,
    name: 'Programmes & Projects',
    active: 'activities',
    link: false,
    target: '',
    status: true,
    submenu: [{
      id: 41,
      name: 'Capacity Building',
      active: 'capacity',
      link: false,
      target: '',
      status: true,
      supersubmenu: [{
        id: 411,
        name: 'SEP4School',
        link: '/activities/capacitySep4school',
        target: '',
        status: true,
      }, {
        id: 412,
        name: 'SEP-ED',
        link: '/activities/capacitySepEd',
        target: '',
        status: true,
      }, {
        id: 413,
        name: 'SEP Youth Camp',
        link: '/activities/capacitySepRegionalYouthCamp',
        target: '',
        status: true,
      }]
    }, {
      id: 42,
      name: 'Community Development',
      active: 'community',
      link: false,
      target: '',
      status: true,
      supersubmenu: [{
        id: 421,
        name: 'SEP & Community Development',
        link: '/activities/communitySEPintheCommunity',
        target: '',
        status: true,
      }]
    }, {
      id: 43,
      name: 'Research & Development',
      active: 'research',
      link: false,
      status: true,
      supersubmenu: [
        {
        id: 431,
        name: 'Classroom Research',
        link: '/activities/researchClassroom',
        target: '',
        status: true,
      },
      {
        id: 432,
        name: 'Research Grants',
        link: '/activities/researchGrants',
        target: '',
        status: true,
      }]
    }, {
      id: 44,
      name: 'Information Exchange',
      active: 'information',
      link: false,
      status: true,
      supersubmenu: [{
        id: 441,
        name: 'Virtual Learning Series',
        link: '/activities/informationVirtualLearningSeries',
        target: '',
        status: true,
      }, {
        id: 442,
        name: 'Other Webinars',
        link: '/activities/informationOtherWebinars',
        target: '',
        status: true,
      }]
    }, {
      id: 45,
      name: 'Partnership & Linkages',
      active: 'partnership',
      link: false,
      target: '',
      status: true,
      supersubmenu: [ {
        id: 453,
        name: 'Promotion of Well-being in Schools',
        link: '/activities/partnershipPromotion',
        target: '',
        status: true,
      },{
        id: 451,
        // name: 'Waste Hero: Reduce to Zero',
        name: '2024 Waste Hero Programme',
        link: '/activities/partnershipWasteHero',
        target: '',
        status: true,
      }, {
        id: 452,
        name: 'Our Partners',
        link: '/activities/partnershipOurPartners',
        target: '',
        status: true,      
      }]
    }, {
      id: 46,
      name: 'Upcoming Events',
      active: 'upcoming',
      link: '/activities/upcomingEvent',
      target: '',
      status: true,
      supersubmenu: []
    }]
  }, {
    id: 5,
    name: 'Awards/Grants',
    active: 'awards',
    link: false,
    target: '',
    status: true,
    submenu: [{
      id: 51,
      name: 'SEAMEO SEPS Awards',
      active: 'seaWasteHeroAwards',
      link: false,
      target: '',
      status: true,
      supersubmenu: [{
        id: 510,
        name: '2024 Waste Hero School Competition',
        link: '/awardsGrants/seaWasteHeroAwards2024',
        target: '',
        status: true,
      },{
        id: 511,
        name: '2023 SEA Waste Hero Awards',
        link: '/awardsGrants/seaWasteHeroAwards',
        target: '',
        status: true,
      }]
    }, {
      id: 52,
      name: 'SEAMEO SEPS Research Grants',
      active: 'researchGrants',
      // link: '/awardsGrants/researchGrants',
      link: false,
      target: '',
      status: true,    
      supersubmenu: [{
        id: 521,
        name: 'Classroom Research',
        link: '/activities/researchClassroom',
        target: '',
        status: false,
      },{
        id: 522,
        name: 'Research Grants',
        link: '/activities/researchGrants',
        target: '',
        status: true,
      }]
    }]
  }, {
    id: 6,
    name: 'Knowledge Hub',
    active: 'knowledge',
    link: false,
    target: '',
    status: true,
    submenu: [{
      id: 61,
      name: 'Decoding SD DNA',
      active: 'decoding',
      link: '/knowledge/decoding',
      target: '',
      status: true,
      supersubmenu: []
    }, {
      id: 62,
      name: 'SEPS Talk',
      active: 'sepsTalk',
      link: '/knowledge/sepsTalk',
      target: '',
      status: true,
      supersubmenu: []
    }, {
      id: 63,
      name: 'Publications',
      active: 'publications',
      link: '/knowledge/publications',
      target: '',
      status: true,
      supersubmenu: []
    }, {
      id: 64,
      name: 'Waste Hero Education Resources',
      active: 'resources',
      link: 'https://www.wasteheroeducation.com/',
      target: '_blank',
      status: true,
      supersubmenu: []
    }]
  }, {
    id: 7,
    name: 'News & Archives',
    active: 'news',
    link: false,
    target: '',
    status: true,
    submenu: [{
      id: 71,
      name: 'News',
      active: 'news',
      link: '/newsandgallery/news',
      target: '',
      status: true,
      supersubmenu: []
    }, {
      id: 72,
      name: 'Gallery',
      active: 'gallery',
      link: '/newsandgallery/gallery',
      target: '',
      status: true,
      supersubmenu: []
    }, {
      id: 73,
      name: 'Archives',
      active: 'archives',
      link: '/newsandgallery/archives',
      target: '',
      status: true,
      supersubmenu: []
    }]
  }, {
    id: 8,
    name: 'Contact',
    active: 'contact',
    link: '/contact',
    target: '',
    status: true,
    submenu: []
  }]

  export {
    menuData
  }